import { BSnackbarProvider } from "@/components/BSnackbarProvider";
import { ConnectionContext } from "@/components/ConnectionContext";
import { navigate } from "@/helpers/navigate";
import { GetConnectionsResponse } from "@/pages/api/connections";
import ErrorProvider from "@aiguestdj/shared/components/ErrorProvider/ErrorProvider";
import { errorBoundary } from "@aiguestdj/shared/helpers/errorBoundary";
import { HomeRounded, LoginOutlined, MoreVert } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Divider, Dropdown, Grid, IconButton, Link, List, ListDivider, ListItem, ListItemButton, ListItemDecorator, Menu, MenuButton, MenuItem, Sheet, Typography } from "@mui/joy";
import axios from "axios";
import { signIn, signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect } from "react";

interface MainLayoutProps {
    children: React.ReactNode,
    loading?: boolean;
    showLogo?: boolean;
}

export default function MainLayout(props: MainLayoutProps) {
    const { children, loading, showLogo } = props;
    const { setLastUpdate, lastUpdate, setPlex, plex, setRoon, roon } = useContext(ConnectionContext);
    const { data: session, status } = useSession();
    const router = useRouter();

    // Handle session login
    useEffect(() => {
        if (session) {
            // Only update once every 30s
            if (!lastUpdate || Date.now() - lastUpdate > 30000) {
                errorBoundary(async () => {
                    const result = await axios.get<GetConnectionsResponse>("/api/connections");
                    if (typeof router.query.plex != 'string') {
                        let plexUrl = result.data.plex;
                        if (plexUrl) {
                            setPlex(plexUrl);
                            localStorage.setItem('plex', plexUrl)
                        }
                    }

                    if (typeof router.query.roon != 'string') {
                        let roonUrl = result.data.roon;
                        if (roonUrl) {
                            setRoon(roonUrl);
                            localStorage.setItem('roon', roonUrl)
                        }
                    }

                    // Store new plex value
                    if (plex && result.data.plex != plex)
                        await axios.put("/api/user", { plex: plex })
                    // Store new roon value
                    if (roon && result.data.roon != roon)
                        await axios.put("/api/user", { roon: roon })

                    setLastUpdate(Date.now());
                })
            }
        }
    }, [status, session])

    useEffect(() => {
        if (!router.isReady || status == "loading") return;
        if (router.query.roon) {
            errorBoundary(async () => {
                if (typeof router.query.roon == 'string') {
                    let localRoonUrl = router.query.roon;
                    if (localRoonUrl) {
                        setRoon(localRoonUrl);
                        localStorage.setItem('roon', localRoonUrl)
                        if (session)
                            await axios.put("/api/user", { roon: localRoonUrl })
                        router.replace('/', undefined, { shallow: true })
                        enqueueSnackbar("Roon connection saved")
                    }
                }
            })
        } else if (router.query.plex) {
            errorBoundary(async () => {
                if (typeof router.query.plex == 'string') {
                    let localPlexUrl = router.query.plex;
                    if (localPlexUrl) {
                        setPlex(localPlexUrl);
                        localStorage.setItem('plex', localPlexUrl)
                        if (session)
                            await axios.put("/api/user", { plex: localPlexUrl })

                        router.replace('/', undefined, { shallow: true })
                        enqueueSnackbar("Plex connection saved")
                    }
                }
            })
        } else {
            // Get data from local storage 
            errorBoundary(async () => {
                let localRoonUrl = (typeof window !== 'undefined') ? localStorage.getItem('roon') : null
                if (localRoonUrl)
                    setRoon(localRoonUrl);
                let localPlexUrl = (typeof window !== 'undefined') ? localStorage.getItem('plex') : null
                if (localPlexUrl)
                    setPlex(localPlexUrl);
            })
        }
    }, [router.isReady, status, session])


    const connectionUrl = roon || plex;
    const isLoading = loading || status == "loading"
    return (
        <Container style={{ maxWidth: "100%", padding: 0 }}>
            <ErrorProvider />
            <BSnackbarProvider />
            <Container style={{ maxWidth: "100%", padding: 0 }}>
                {isLoading &&
                    <Box display={'flex'} justifyContent={'center'} pt={12}>
                        <CircularProgress size="md" />
                    </Box>}
                {!isLoading &&
                    <>
                        <Box pt={6}>
                            {children}
                        </Box>
                        <Sheet sx={{ position: "fixed", top: 0, zIndex: 99, width: "100vw", borderBottom: "1px solid rgba(255,255,255,0.4)" }}>
                            <Box p={2}>
                                <Box display={{ xs: "block", md: "none" }}>
                                    <Dropdown>
                                        <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
                                            <MoreVert />
                                        </MenuButton>
                                        <Menu placement='bottom-start'>
                                            <MenuItem selected={router.asPath == '/'} component="a" href="/">Home</MenuItem>
                                            {session && session.user.premium &&
                                                <MenuItem component="a" href={`/dashboards`}>Dashboards</MenuItem>
                                            }
                                            {session && roon &&
                                                <MenuItem component="a" href={`${roon}`}>Roon</MenuItem>
                                            }
                                            {session && plex &&
                                                <MenuItem component="a" href={`${plex}`}>Plex</MenuItem>
                                            }
                                            {plex &&
                                                <MenuItem component="a" href={`${plex}/import`}>Import playlist</MenuItem>
                                            }
                                            {connectionUrl &&
                                                <MenuItem component="a" href={`${connectionUrl}/generate`}>Generate playlist</MenuItem>
                                            }
                                            <MenuItem selected={router.asPath == '/browse'} component="a" href="/browse">Browse</MenuItem>
                                            <ListDivider />
                                            {!session &&
                                                <MenuItem onClick={() => signIn()} >Sign in</MenuItem>
                                            }
                                            <MenuItem selected={router.asPath.indexOf('/connections/') > -1} component={'a'} href="/connections/roon">Manage Connections</MenuItem>
                                            {session &&
                                                <MenuItem onClick={() => signOut()}>Sign out</MenuItem>
                                            }
                                        </Menu>
                                    </Dropdown>

                                </Box>
                                <Box display={{ xs: "none", md: "block" }}>
                                    <Grid container>
                                        <Grid xs={2}>
                                            {session ?
                                                <Dropdown>
                                                    <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
                                                        <MoreVert />
                                                    </MenuButton>
                                                    <Menu placement='bottom-start'>
                                                        {session && session.user.premium &&
                                                            <MenuItem component="a" href={`/dashboards`}>Dashboards</MenuItem>
                                                        }
                                                        <MenuItem component={'a'} href='/connections/roon' onClick={(e) => navigate(e, '/connections/roon')}>Manage Connections</MenuItem>
                                                        {session &&
                                                            <MenuItem onClick={() => signOut()}>Logout</MenuItem>
                                                        }
                                                    </Menu>
                                                </Dropdown>
                                                :
                                                <Button startDecorator={<LoginOutlined />} onClick={() => signIn()} >Sign in</Button>
                                            }
                                        </Grid>
                                        <Grid xs={8}>
                                            <List sx={{ justifyContent: "center", gap: 2 }} orientation="horizontal">
                                                <ListItem>
                                                    <ListItemButton selected={router.asPath == '/'} role="menuitem" component="a" href="/">
                                                        <ListItemDecorator >
                                                            <HomeRounded />
                                                        </ListItemDecorator>
                                                    </ListItemButton>
                                                </ListItem>
                                                {connectionUrl && !session &&
                                                    <ListItem>
                                                        <ListItemButton selected={router.asPath.indexOf('/connections/') > -1} role="menuitem" component="a" href="/connections/roon">
                                                            Manage Connections
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                {session && roon &&
                                                    <ListItem>
                                                        <ListItemButton role="menuitem" component="a" href={`${roon}`}>
                                                            Roon
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                {session && plex &&
                                                    <ListItem>
                                                        <ListItemButton role="menuitem" component="a" href={`${plex}`}>
                                                            Plex
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                {!connectionUrl &&
                                                    <ListItem>
                                                        <ListItemButton selected={router.asPath.indexOf('/connections') > -1} role="menuitem" component="a" href="/connections/roon">
                                                            Manage connections
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                {plex &&
                                                    <ListItem>
                                                        <ListItemButton role="menuitem" component="a" href={`${connectionUrl}/import`}>
                                                            Import playlist
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                {connectionUrl &&
                                                    <ListItem>
                                                        <ListItemButton role="menuitem" component="a" href={`${connectionUrl}/generate`}>
                                                            Generate playlist
                                                        </ListItemButton>
                                                    </ListItem>
                                                }
                                                <ListItem>
                                                    <ListItemButton selected={router.asPath == '/browse'} role="menuitem" component="a" href="/browse">
                                                        Browse
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid xs={2}></Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Sheet>
                        <Box m={2}>
                            <Divider sx={{ mb: 1 }} />
                            <Box display={'flex'} justifyContent={'flex-end'} zIndex={1} position={"relative"} gap={2} pr={2}>
                                <Link color="neutral" fontSize={".8em"} underline="hover" href={'/privacy'}>Privacy Policy</Link>
                                <Typography color="neutral" fontSize={".8em"}>AI Guest DJ 2024</Typography>
                            </Box>
                        </Box>
                    </>
                }
            </Container>
        </Container >
    )
}